import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import "../../i18n";
import { useTranslation } from "react-i18next";
import "./Navbar.scss";
import { motion } from "framer-motion";
import useDetectView from "../Hook/useDetectView";
import { View } from "../../Data/Constants";
import { Logo_Circle, Logo_Default } from "../../Data/Constants";

export const NavBar: React.FC = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<string | null>(null);
  const currentView = useDetectView();
  const [opacity, setOpacity] = useState(0);
  const location = useLocation();
  const [sticky, setSticky] = useState(false);
  const navbarRef = useRef<HTMLDivElement | null>(null);

const adjustNavbar = () => {
  if (location.pathname === "/") {
    const newOpacity = Math.min(window.scrollY / 200, 1);
    setOpacity(newOpacity);

    if (window.scrollY > 100) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  } else {
    setOpacity(1);  // Always solid on other pages
    setSticky(true); // Always sticky
  }
};

const onScroll = () => {
  adjustNavbar();
};

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    adjustNavbar();
    return () => window.removeEventListener("scroll", onScroll);
  }, [location.pathname]);

  const handleClickfunc = async () => {
    await /* API call here */
    navigate('/nousrejoindre');
  }

  const currentLogo = Logo_Circle;

  useEffect(() => {
    setCurrentLanguage(i18n.language.toUpperCase());
  }, [i18n.language]);

  interface ClassNameFuncProps {
    isActive: boolean;
  }
  
  const classNameFunc = ({ isActive }: ClassNameFuncProps) =>
    isActive ? "navbar-item active-nav-item " : "navbar-item ";

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    if(lng==="en"){
      setCurrentLanguage("EN")
    }
    if(lng==="fr"){
      setCurrentLanguage("FR")
    }
  };

  return (
      <motion.div 
      ref={navbarRef}
      initial={{ opacity: 0, x: '-100vw' }} 
      animate={{ opacity: 1, x: 0 }} 
      transition={{ duration: 1 }} 
      className={`custom-nav ${sticky && currentView!==View.Mobile ? 'sticky-nav' : ''}`}
      style={{
        position: sticky && currentView!==View.Mobile ? "sticky" : "absolute",
        top: sticky && currentView!==View.Mobile ? 0 : 'initial', // Add this line
        backgroundColor: currentView===View.Desktop || currentView===View.LargeTablet || currentView===View.Tablet ? `rgba(16, 26, 44, ${opacity})` : "white"
      }}
    >
    <Navbar expand="md">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img className="img-logo" src={currentLogo} alt="Simdev Logo"></img>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink className={classNameFunc} to="/histoire">
              {t("navbar.history-link")}
            </NavLink>
            <NavLink to="/construction" className={classNameFunc}>
              {t("navbar.construction-link")}
            </NavLink>
            <NavLink className={classNameFunc} to="/gestion">
              {t("navbar.management-link")}
            </NavLink>
            <NavLink className={classNameFunc} to="/projets">
              {t("navbar.projects-link")}
            </NavLink>
            <NavLink className={classNameFunc} to="/emplois">
              {t("navbar.careers-link")}
            </NavLink>
            {
              currentView===View.Tablet || currentView===View.Mobile ?
              <NavLink className={classNameFunc} to="/nousrejoindre">{t("navbar.contact-button")}</NavLink>
              :
              <></>
            }
          </Nav>

            { currentView===View.Desktop || currentView===View.LargeTablet ? 
            <span>
            <button
              className={sticky ? "contact-button" : "contact-button-transparent"}
              onClick={handleClickfunc}
            >
              {t("navbar.contact-button")}
            </button>
            </span> : <></>
            }
            <NavDropdown
                className="language-dropdown"
                title={currentLanguage}
                menuVariant="light">
              <NavDropdown.Item  onClick={() => changeLanguage("en")}>English</NavDropdown.Item>
              <NavDropdown.Item onClick={() => changeLanguage("fr")}>Français</NavDropdown.Item>
            </NavDropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </motion.div>
  );
};

