import image1 from "../../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-01.jpeg";
import image2 from "../../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-02.jpeg";
import image3 from "../../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-03.jpeg";
import image4 from "../../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-04.jpeg";
import image5 from "../../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-05.jpeg";
import image6 from "../../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-06.jpeg";
import image7 from "../../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-10.jpeg";
import image8 from "../../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-11.jpeg";
import image9 from "../../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-12.jpeg";
import image10 from "../../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-12A.jpeg";
import image11 from "../../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-13.jpeg";
import image12 from "../../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-14.jpeg";
import image13 from "../../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-15.jpeg";



/* IMAGES LEVIVO LAPRAIRIE*/

export const slidesLVLP = [
    {
        url: image1
    },
    {
        url: image2
    },
    {
        url: image3
    },
    {
        url: image4
    },
    {
        url: image5
    },
    {
        url: image6
    },
    {
        url: image7
    },
    {
        url: image8
    },
    {
        url: image9
    },
    {
        url: image10
    },
    {
        url: image11
    },
    {
        url: image12
    },
    {
        url: image13
    },
    
    
]

export default slidesLVLP;