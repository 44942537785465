import "../../i18n";
import { useTranslation } from "react-i18next";
import { View } from "../../Data/Constants";
import LocationImage from "../../Assets/images/Shutterstock/HappyCouple.jpeg"
import FinancingImage from "../../Assets/images/Shutterstock/Accountant.jpeg"
import MarketingImage from "../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-07.jpeg"
import MaintenanceImage from "../../Assets/images/Shutterstock/MaintenanceWorker.jpeg"
import OptimizationImage from "../../Assets/images/Shutterstock/Financing.jpeg"
import SimpleTextSection from "../../Components/Text/SimpleTextSection/SimpleTextSection";
import useDetectView from "../../Components/Hook/useDetectView";
import './ManagementPage.scss'

//FOR TESTING PURPOSE
import IntroSectionImage from '../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-02.jpeg'
import SingleLayerIntroSection from "../../Components/Text/ParallaxTitle/Simple/SimpleParallaxTitle";
import { HAngledBackgroundTextSection } from "../../Components/Text/AngledBackgroundTextSection/AngledBackgroundTextSection";

export default function Construction(){
    const { t } = useTranslation();
    const currentView = useDetectView();

    let containerHeight = "500"; // Default height

    switch (currentView) {
    case View.Mobile:
        containerHeight = "1200";
        break;
    case View.Tablet:
        // Set height for tablet view, if different from default
        containerHeight = "850";
        break;
    default:
        break;
    }
    
    return (
        <div>
            <SingleLayerIntroSection title={t("management.intro.title")} backgroundImage={IntroSectionImage}/>
                <div className="section-container">
                    <SimpleTextSection 
                    title={t("management.services.rental.title")}
                    text={t("management.services.rental.text")}
                    isDark={false}
                    height={containerHeight}
                    position="left" 
                    image={LocationImage} 
                    isRounded={currentView===View.Tablet || currentView===View.Desktop || currentView===View.LargeTablet ? true : false }
                    imagePosition="child"
                    imageFit="cover"
                    isImageRounded={true}
                    delayInSecond={0} 
                    durationInSecond={2}
                    enableShadow={false}/>
                </div>
                <HAngledBackgroundTextSection>
                    <div className="bg-custom-dark section-container">
                        <SimpleTextSection 
                        title={t("management.services.financing.title")} 
                        text={t("management.services.financing.text")} 
                        isDark={true} 
                        height={containerHeight}
                        position="right" 
                        image={FinancingImage} 
                        isRounded={currentView===View.Tablet || currentView===View.Desktop || currentView===View.LargeTablet ? true : false }
                        imagePosition="child"
                        imageFit="cover"
                        isImageRounded={true}
                        delayInSecond={0} 
                        durationInSecond={2}
                        enableShadow={false}/>
                    </div>
                </HAngledBackgroundTextSection>
                <div className="section-container">
                    <SimpleTextSection 
                    title={t("management.services.marketing.title")}
                    text={t("management.services.marketing.text")}
                    isDark={false} 
                    position="left" 
                    height={containerHeight}
                    image={MarketingImage} 
                    isRounded={currentView===View.Tablet || currentView===View.Desktop || currentView===View.LargeTablet ? true : false }
                    imagePosition="child"
                    imageFit="cover"
                    isImageRounded={true}
                    delayInSecond={0} 
                    durationInSecond={2}
                    enableShadow={false}/>
                </div>
                <HAngledBackgroundTextSection>
                    <div className="bg-custom-dark section-container">
                        <SimpleTextSection 
                        title={t("management.services.maintenance.title")}
                        text={t("management.services.maintenance.text")}
                        isDark={true} 
                        position="right"
                        height={containerHeight}
                        image={MaintenanceImage} 
                        isRounded={currentView===View.Tablet || currentView===View.Desktop || currentView===View.LargeTablet ? true : false }
                        imagePosition="child"
                        imageFit="cover"
                        isImageRounded={true}
                        delayInSecond={0} 
                        durationInSecond={2}
                        enableShadow={false}/>
                    </div>
                </HAngledBackgroundTextSection>
                <div className="section-container">
                    <SimpleTextSection 
                    title={t("management.services.optimization.title")}
                    text={t("management.services.optimization.text")}
                    isDark={false} 
                    position="left" 
                    height={containerHeight}
                    image={OptimizationImage} 
                    isRounded={currentView===View.Tablet || currentView===View.Desktop || currentView===View.LargeTablet ? true : false }
                    imagePosition="child"
                    imageFit="cover"
                    isImageRounded={true}
                    delayInSecond={0} 
                    durationInSecond={2}
                    enableShadow={false}/>
                </div>
            </div>
    )
}

