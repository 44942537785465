import './ContactForm.scss'
import { FaAddressCard, FaEnvelope, FaPen, FaPhone } from "react-icons/fa";
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import video1 from '../../Assets/videos/DJI_0125.MP4';
import { motion } from "framer-motion";
import "../../i18n";
import { useTranslation } from "react-i18next";
import React from 'react';
import LoadingButton from '../../Components/Buttons/LoadingButton/LoadingButton';

export const ContactForm = () => {
    const { t, i18n } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [show, setShow] = useState(false);
    const [isSubmissionError, setSubmissionError] = useState(false);
    const [requestResult, setRequestResult] = useState({ status: "", message: "" });
    const fnameRef = React.useRef<HTMLInputElement>(null);
    const lnameRef = React.useRef<HTMLInputElement>(null);
    const emailRef = React.useRef<HTMLInputElement>(null);
    const messageRef = React.useRef<HTMLTextAreaElement>(null);
    const [currentLanguage, setCurrentLanguage] = useState<string | null>(i18n.language?.toUpperCase() || "FR");


    const handleButtonClick = () => {
        // Create a new synthetic event
        const fakeEvent = {
          preventDefault: () => {},
          target: {
            elements: {
              // Map the refs or form fields here
              fname: { value: fnameRef.current?.value },
              lname: { value: lnameRef.current?.value },
              email: { value: emailRef.current?.value },
              message: { value: messageRef.current?.value },
              bot_field: { value: '' }, // Update according to your form structure
            }
          }
        };
      
        handleSubmit(fakeEvent);
      };

    useEffect(() => {
        setCurrentLanguage(i18n.language.toUpperCase());
      }, [i18n.language]);

    const [status, setStatus] = useState("Submit");
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setSubmissionError(false);
        setIsSubmitting(true); // Start submitting
        setStatus("Sending...");
        try {
            const { lname, fname, email, message, bot_field } = e.target.elements;
            let details = {
                fname: fname.value,
                lname: lname.value,
                email: email.value,
                message: message.value,
                bot_field: bot_field.value,
                lang: currentLanguage?.toString(),
            };
    
            let response = await fetch("https://api.simdev.ca/contact", {
            //let response = await fetch("http://localhost:5000/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                //credentials: 'include',
                body: JSON.stringify(details),
            });
    
            if (!response.ok) {
                let errorResult = await response.json();
                setRequestResult({
                    status: errorResult.status,
                    message: errorResult.message
                });
                handleShow();
                clearFields();
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            else{
                let successResult = await response.json();
                setRequestResult({
                    status: successResult.status,
                    message: successResult.message
                });
                handleShow();
                clearFields();
            }
        } catch (error) {
            console.error("Submission error:", error);
            setSubmissionError(true)
            // Show some error to the user as well if needed
        } finally {

            setIsSubmitting(false); // Stop submitting regardless of outcome
            setStatus("Submit");
        }
        
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const clearFields = () => {
        if (fnameRef.current) fnameRef.current.value = '';
        if (lnameRef.current) lnameRef.current.value = '';
        if (emailRef.current) emailRef.current.value = '';
        if (messageRef.current) messageRef.current.value = '';

        removeNotEmpty();
    }

    const removeNotEmpty = () => {
        const inputs = document.querySelectorAll<HTMLInputElement>(".contact-input")
    
        inputs.forEach(ipt => {
            if (ipt) {
                const parent = ipt.parentNode as HTMLElement;
                if(ipt.value == ""){
                        parent.classList.remove("not-empty");
                }
            }
        })
    }

    useEffect(() => {
        const inputs = document.querySelectorAll<HTMLInputElement>(".contact-input")
    
        inputs.forEach(ipt => {
            if (ipt) {
                ipt.addEventListener("focus", () => {
                    const parent = ipt.parentNode as HTMLElement;
                    parent.classList.add("focus");
                    parent.classList.add("not-empty");
                })
                ipt.addEventListener("blur", () => {
                    const parent = ipt.parentNode as HTMLElement;
                    if(ipt.value == ""){
                        parent.classList.remove("not-empty");
                    }
                    parent.classList.remove("focus");
                })
            }
        })
      }, []);

  return (
    <section className="contact">
        <div className="contact-container">
            <motion.div className="contact-column-left" initial={{ opacity: 0, x: '-100vw' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                <div className="form-wrapper">
                    <div className="contact-heading">
                        <h1>{t("contact.title.title")}<span>.</span></h1>
                        <div className="text"><span><FaEnvelope/> </span><a style={{marginRight:"45px"}} href="mailto:info@simdev.ca">info@simdev.ca</a><span><FaPhone/> </span>(514) 316-8685</div>
                    </div>
                    <form onSubmit={handleSubmit} method="post" className="contact-form">
                        <div className="input-wrap">
                            <input ref={fnameRef} className="contact-input" type="text" autoComplete="off" id="fname" name="fname" required/>
                            <label>{t("contact.form.fields.first-name")}</label>
                            <i className="icon"><FaAddressCard/></i>
                        </div>

                        <div className="input-wrap">
                            <input ref={lnameRef} className="contact-input" type="text" autoComplete="off" id="lname" name="lname" required/>
                            <label>{t("contact.form.fields.last-name")}</label>
                            <i className="icon"><FaAddressCard/></i>
                        </div>

                        <div className="input-wrap w-100">
                            <input ref={emailRef} className="contact-input" type="email" autoComplete="off" id="email" name="email" required/>
                            <label>{t("contact.form.fields.email")}</label>
                            <i className="icon"><FaEnvelope/></i>
                        </div>

                        <div className="input-wrap textarea w-100">
                            <textarea ref={messageRef} className="contact-input" autoComplete="off" id="message" name="message" required/>
                            <label>{t("contact.form.fields.message")}</label>
                            <i className="icon"><FaPen/></i>
                        </div>

                        {/*BOT DETECTION*/}
                        <div>
                            <input type="text" name="bot_field" style={{ display: "none" }} />
                        </div>
                        <div className='text-warp w-100'>
                            <div>{t("contact.form.warning.partOne")}<a href="/avis-legal"> {t("contact.form.warning.partTwo")}</a> {t("contact.form.warning.partThree")}<a href="/politique-confidentialite"> {t("contact.form.warning.partFour")}</a> {t("contact.form.warning.partFive")}</div>
                        </div>
                        <div className='button-wrap w-100'>
                            <LoadingButton onClick={handleButtonClick} isSubmitting={isSubmitting} text={t("contact.form.button")} error={isSubmissionError}/>
                        </div>
                    </form>
                </div>
            </motion.div>
            <motion.div className="contact-column-right" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5, duration: 1.5 }}>
                <div className="image-wrapper">
                    <video src={video1} className='vid' disablePictureInPicture={true} autoPlay={true} muted loop playsInline={true} />
                    <div className="video-overlay"></div>
                </div>
            </motion.div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{requestResult.status}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{requestResult.message}</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    </section>
  );
};

export default ContactForm;

