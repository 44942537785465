import './Privacy.scss'

export function Privacy_fr() {

      return (
        <div>
            <div className="privacy-policy justify-text">
                <h1>Politique de Confidentialité</h1>
                <div className="section">
                    <h2>Collecte de Données</h2>
                    <p>Nous recueillons des informations personnelles via notre formulaire de contact, y compris votre nom, adresse e-mail et tout détail supplémentaire que vous fournissez.</p>
                </div>
                <div className="section">
                    <h2>Utilisation des Informations</h2>
                    <p>Les informations que vous fournissez sont utilisées exclusivement pour répondre à vos demandes de renseignements ou offrir un soutien.</p>
                </div>
                <div className="section">
                    <h2>Protection des Informations</h2>
                    <p>Nous prenons la sécurité de vos informations personnelles au sérieux et avons mis en place diverses mesures pour les protéger.</p>
                </div>
                <div className="section">
                    <h2>Contactez-Nous</h2>
                    <p>Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter en utilisant les informations disponibles sur notre site web.</p>
                </div>
            </div>
        </div>
      );
    }
    
    export default Privacy_fr;