import './Privacy.scss'

export function Privacy_en() {

      return (
        <div>
          <div className="privacy-policy justify-text">
            <h1>Privacy Policy</h1>
            <div className="section">
                <h2>Data Collection</h2>
                <p>We collect personal information through our contact form, including your name, email address, and any additional details you provide.</p>
            </div>
            <div className="section">
                <h2>Use of Information</h2>
                <p>The information you provide is used exclusively for responding to your inquiries or offering support.</p>
            </div>
            <div className="section">
                <h2>Protection of Information</h2>
                <p>We take the security of your personal information seriously and have implemented various measures to safeguard it.</p>
            </div>
            <div className="section">
                <h2>Contact Us</h2>
                <p>If you have questions about this privacy policy, please contact us using the information available on our website.</p>
            </div>
          </div>
        </div>
      );
    }
    
    export default Privacy_en;