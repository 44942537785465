import React from "react";
import './SimpleTextSection.scss'
import { useSpring, animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import useDetectView from "../../Hook/useDetectView";
import { View } from "../../../Data/Constants";

enum TextPosition {
  start = "start",
  end = "end",
}

interface SimpleTextSectionProps {
    isDark: boolean;
    position?: string;
    title: string;
    height?: string;
    text: string;
    image: string;
    imageAlt?: string;
    delayInSecond?: number;
    durationInSecond?: number;
    imagePosition?: string;
    imageFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
    isRounded?: boolean;
    isImageRounded?: boolean;
    enableShadow?: boolean
}
  
export const SimpleTextSection: React.FC<SimpleTextSectionProps> = ({
  isDark,
  position = 'start',
  title,
  height = 'auto',
  text,
  image,
  imageAlt = "",
  delayInSecond = 2,
  durationInSecond = 2,
  enableShadow = false,
  imagePosition = "child",
  imageFit = "cover",
  isRounded = false,
  isImageRounded = false,
}) => {

  const currentView = useDetectView();
  
  let pPosition = "";

  let flexDirection: 'row' | 'row-reverse' | 'column' | 'column-reverse' = 'row';

  switch (position) {
    case 'start':
    case 'left':
      pPosition = TextPosition.start;
      flexDirection = 'row';
      break;
    case 'end':
    case 'right':
      pPosition = TextPosition.end;
      flexDirection = 'row-reverse';
      break;
    default:
      break;
  }
  
  const objectFitStle = {
    objectFit: imageFit,
  }

  const backgroundStyle = {
    ...objectFitStle,
    backgroundImage: `url(${image})`,
  };

  let imagePositionClass = "";
if (imagePosition === "half") {
  if (position === 'start' || position === 'left') {
    imagePositionClass = "left-position";
  }
}

let imageComponent;
switch (imagePosition) {
  case "background":
    imageComponent = <div className={`image-container background`}  style={isRounded && pPosition===TextPosition.end && currentView===View.Desktop || currentView===View.LargeTablet ? {
      ...backgroundStyle,
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      borderTopLeftRadius: '25px',
      borderBottomLeftRadius: '25px'} 
      : isRounded && pPosition===TextPosition.start && currentView===View.Desktop ? {
        ...backgroundStyle,
        borderTopRightRadius: '25px',
        borderBottomRightRadius: '25px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px'}
      : currentView===View.Mobile ? {
        ...backgroundStyle,
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px'}
      :{
        ...backgroundStyle,
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '25px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '25px'}
    }>
    </div>;
    break;
  case "half":
    imageComponent = (
      <div className={`image-container half`}>
        <img src={image} alt={imageAlt} style={
          isRounded && pPosition===TextPosition.end && currentView===View.Desktop || currentView===View.LargeTablet ? {
            ...objectFitStle,
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
          borderTopLeftRadius: '25px',
          borderBottomLeftRadius: '25px'} 
          : isRounded && pPosition===TextPosition.start && currentView===View.Desktop ? {
            ...objectFitStle,
          borderTopRightRadius: '25px',
          borderBottomRightRadius: '25px',
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px'}
          : currentView===View.Mobile ? {
            ...objectFitStle,
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px'}
          : {
            ...objectFitStle,
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px'}
          } />
      </div>
    );
    break;
    case "child":
      const roundedStyle = isImageRounded ? { borderRadius: '25px' } : {};
      imageComponent = (
        <div className="image-container child">
          <img src={image} alt={imageAlt} 
          style={{
            ...roundedStyle,
            objectFit: imageFit}} 
            />
        </div>
      );
      break;
    default:
      break;
  }

  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  
  const animationProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: inView ? 1 : 0 },
    delay: delayInSecond * 1000,
    config: {
      duration: durationInSecond * 1000,
    },
  });

  const containerHeight = height==="auto" ? 'auto': `${height}px`

  const containerStyle = currentView===View.Desktop || currentView===View.LargeTablet ? {
    flexDirection: flexDirection,

    boxShadow: enableShadow ? '0px 0px 20px 5px rgba(0,0,0,0.2)': '0px 0px 0px 0px rgba(0,0,0,0)',
    borderRadius: isRounded ? '25px': '0px'
} : {
  flexDirection: 'column' as 'column',
  boxShadow: enableShadow ? '0px 0px 20px 5px rgba(0,0,0,0.2)': '0px 0px 0px 0px rgba(0,0,0,0)',
  borderRadius: isRounded ? '25px': '0px'
};


  return (
      <div className={isDark ? 'text-section-container bg-custom-dark' : 'text-section-container bg-custom-light'} style={{
        ...containerStyle,
        height: containerHeight
        }}>
        <animated.div className="div-container-desc" style={animationProps} ref={ref}>
          <div className="justify-text">
            <h1 className="desc-title">{title}</h1>
            <p className="text-desc">{text}</p>
          </div>
        </animated.div>
        {imageComponent}
      </div>
  )
};

export default SimpleTextSection;

