import "../../i18n";
import { useTranslation } from "react-i18next";
import { ConstructionPageData } from "../../Data/Translation/ConstructionPage/Data_ConstructionPage";
import "./ConstructionPage.scss"; // import CSS file
import { useInView } from "react-intersection-observer";
import { HAngledBackgroundTextSection } from "../../Components/Text/AngledBackgroundTextSection/AngledBackgroundTextSection";
import { Variants, motion, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";
import image from '../../Assets/images/Pages/construction-image.jpg';
import SingleLayerIntroSection from "../../Components/Text/ParallaxTitle/Simple/SimpleParallaxTitle";
import '../../Styles/Shared/utilities.scss'

interface InViewMotionDivProps {
    children: React.ReactNode;
    variants: Variants;
    className?: string;
}

const leftSideVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } }
};

const rightSideVariants = {
    hidden: { x: 100, opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 1 } }
};

export default function Construction(){
    const { t, i18n } = useTranslation();
    const currentPageData = i18n.language === 'fr' ? ConstructionPageData.constructionPage_fr : ConstructionPageData.constructionPage_en;
    const { inView } = useInView({
        triggerOnce: true,
    });

    const leftSideControls = useAnimation();
    const rightSideControls = useAnimation();

    useEffect(() => {
        if (inView) {
            leftSideControls.start('visible');
            rightSideControls.start('visible');
        }
    }, [inView, leftSideControls, rightSideControls]);
    
    return (
        <div>
            <SingleLayerIntroSection 
                title={t("construction.title.title")} 
                backgroundImage={image} 
                subtitle="" 
                textBackground={{enabled: true, color: "red", rounded: true, opacity: 0.5}}/>
                <div>
                    <div className="section-container">
                        <div className="dual-content-section">
                            <InViewMotionDiv variants={leftSideVariants} className="left-side">
                                <h3>{t("construction.phases.pre_dev.title")}</h3>
                                <p className="justify-text">{t("construction.phases.pre_dev.text")}</p>
                            </InViewMotionDiv>
                            <InViewMotionDiv variants={rightSideVariants} className="right-side">
                                <ul className="services-list">
                                    {currentPageData.phases.pre_dev.services.map(service => <li className="services-list-item">{service}</li>)}
                                </ul>
                            </InViewMotionDiv>
                        </div>
                    </div>
                    <HAngledBackgroundTextSection>
                        <div className="section-container height-800">
                            <div className="dual-content-section">
                                <InViewMotionDiv variants={leftSideVariants} className="left-side">
                                    <h3>{t("construction.phases.conception.title")}</h3>
                                    <p className="justify-text">{t("construction.phases.conception.text")}</p>
                                </InViewMotionDiv>
                                <InViewMotionDiv variants={rightSideVariants} className="right-side">
                                    <ul className="services-list">
                                        {currentPageData.phases.conception.services.map(service => <li className="services-list-item">{service}</li>)}
                                    </ul>
                                </InViewMotionDiv>
                            </div>
                        </div>
                    </HAngledBackgroundTextSection>
                    <div className="section-container">
                        <div className="dual-content-section">
                            <InViewMotionDiv variants={leftSideVariants} className="left-side">
                                <h3>{t("construction.phases.management.title")}</h3>
                                <p className="justify-text">{t("construction.phases.management.text")}</p>
                            </InViewMotionDiv>
                            <InViewMotionDiv variants={rightSideVariants} className="right-side">
                                <ul className="services-list">
                                    {currentPageData.phases.management.services.map(service => <li className="services-list-item">{service}</li>)}
                                </ul>
                            </InViewMotionDiv>
                        </div>
                    </div>
                    <HAngledBackgroundTextSection>
                    <div className="section-container height-900">
                        <div className="dual-content-section">
                            <InViewMotionDiv variants={leftSideVariants} className="left-side">
                                <h3>{t("construction.phases.construction.title")}</h3>
                                <p className="justify-text">{t("construction.phases.construction.text")}</p>
                            </InViewMotionDiv>
                            <InViewMotionDiv variants={rightSideVariants} className="right-side">
                                <ul className="services-list">
                                    {currentPageData.phases.construction.services.map(service => <li className="services-list-item">{service}</li>)}
                                </ul>
                            </InViewMotionDiv>
                        </div>
                    </div>
                    </HAngledBackgroundTextSection>
                    <div className="section-container">
                        <div className="dual-content-section">
                            <InViewMotionDiv variants={leftSideVariants} className="left-side">
                                <h3>{t("construction.phases.post.title")}</h3>
                                <p className="justify-text">{t("construction.phases.post.text")}</p>
                            </InViewMotionDiv>
                            <InViewMotionDiv variants={rightSideVariants} className="right-side">
                                <ul className="services-list">
                                    {currentPageData.phases.post.services.map(service => <li className="services-list-item">{service}</li>)}
                                </ul>
                            </InViewMotionDiv>
                        </div>
                    </div>
                </div>
        </div>
    )
}

const InViewMotionDiv: React.FC<InViewMotionDivProps> = ({ children, variants, className }) => {
    const [ref, inView] = useInView({ triggerOnce: true });

    return (
        <motion.div
            ref={ref}
            variants={variants}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            className={className}
        >
            {children}
        </motion.div>
    );
};


